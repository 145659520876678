////////////////////////////////////////////
// Fonctions reliées à la carte
////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { HTMLMarker, initMarker } from './mapMarker.js'
import { mapStyle } from './mapStyle.js' // récupère le style de la carte
import { mapOverlay } from './mapOverlay.js' // récupère l’objet de gestion des panels
import { mapFilter } from './mapFilter.js' // récupère l’objet de gestion des panels
import { mapUrl } from './mapUrl'
import Overlay from '../../classes/overlay/Overlay';
import barba from '@barba/core'

const $loaderId = '#googleMapLoading'
const $containerId = '#googleMap'

let $loader = null          // Loader jquery element
let $container = null       // Map container jquery element
let map = null              // Google map reference
let pois = null             // List of the POIs
let categories = null       // categories
let activeKMLOnLoad = null  // Unique KML marker on loading.

/**
 * Lazy loading of Google Map. Possibly called multiple times in 1 page
 */
export function googleMap() {

  if (!map) {

    initMap()
    loadCategories()

    // Wait for gmap to be loaded AND displayed before adding the first markers
    google.maps.event.addListenerOnce(map, 'tilesloaded', event => {
      refreshMarkers(map)
    })

  } else {

    // Wait for fade animation to end and reset bounds
    refreshMarkers(map)
  }
}

/**
 * Initialize all google map components
 */
function initMap() {

  const mapOption = {
    zoom: 14,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE
      ]
    },
    gestureHandling: 'cooperative',
    center: new google.maps.LatLng(46.8063966429265, -71.35787206442006),
    styles: mapStyle,
    mapTypeId: 'satellite',
  }

  $loader = $($loaderId)
  $container = $($containerId)
  map = new google.maps.Map(document.getElementById($container.attr('id')), mapOption)

  // initialisation des marqueurs suite au chagement de Google
  mapUrl.onLoadInit()
  initMarker()
  mapOverlay.init()

  // En mobile, un click sur la carte referme l'overlay du menu.
  if ( $(window).width() > 1024 )
    $('#googleMap').removeClass('closeOverlay')
  else
    $('#googleMap').addClass('closeOverlay')
}

/**
 * De-reference all google map components. Called on page change
 */
export function destroyMap() {

  // Reset UI
  let event = new CustomEvent('panel.hide')
  window.dispatchEvent(event)
  mapFilter.resetUISubCategory()
  mapFilter.closeAllSubCategory()

  map = null
  pois = null
}

/**
 * Initialize an ajax request to update the markers
 * Ignore if the map hasn't been initialized yet (since it's lazy loaded)
 */
export function refreshMarkers() {

  if (!map) return

  // recuperation de tous les POIS
  $.ajax({
    method: 'GET',
    url: '/api/v1/pois',
  }).done(resp => {
    if (resp.status === 'success') {
      updateMarkers(resp.data)
      mapFilter.init()
      checkForKMLOnLoad()
      HTMLMarker.fitBounds()
      hideLoader()
    }
  })
}

function checkForKMLOnLoad() {

  // KML On Load.
  if(activeKMLOnLoad)
  {
    HTMLMarker.hideAll()
    HTMLMarker.showUniqueMarker(activeKMLOnLoad)
  }
}

/**
 * Ajoute les marqueurs en overlay
 */
function updateMarkers(data) {

  var pois = data.pois

  pois.map(poi => {
    const markerPos = poi.position
    var htmlMarker = new HTMLMarker(map, markerPos.lat, markerPos.lng, poi.id, poi.slug, poi.icon ,poi.kml, poi.categoriesID, poi.accessibility, poi.nom)
    if (htmlMarker.activeOnLoad && htmlMarker.kmlUrl)
      activeKMLOnLoad = htmlMarker.id
  })
}

function showLoader() {
  $loader.stop().fadeIn()
}

function hideLoader() {
  $loader.stop().fadeOut()
}

function loadCategories() {

  $.ajax({
    method: 'GET',
    url: '/api/v1/pois/categories',
  }).done(resp => {
    if (resp.status === 'success') {
      categories = resp.data.poisCategoriesList
      window.poisCategories = categories
    }
  })
}

export function boundMap() {

  let bounds = new google.maps.LatLngBounds()
  map.fitBounds(bounds, 1)
  let zoom = map.getZoom()
  map.setZoom(zoom)
}

export function initMapHeight() {

  var windowHght
  var alertHght = $('#alertBar').height()
  var headerHght = $('header').height() - alertHght

  if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHght + alertHght)
  else windowHght = $(window).height() - headerHght
  $('#mapWrapper').css('height', windowHght + 'px')

  $(window).resize(function () {
    var alertHght = $('#alertBar').height()
    var headerHght = $('header').height() - alertHght
    if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHght + alertHght)
    else windowHght = $(window).height() - headerHght
    $('#mapWrapper').css('height', windowHght + 'px')
  })
}

export function initAddressAutoComplete() {

  var options = {
    types: ['address'],
    componentRestrictions: {country: 'ca'}
  }

  var places = new google.maps.places.Autocomplete(document.getElementById('adresse'), options)

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }

      var circle = new google.maps.Circle({center: geolocation, radius: position.coords.accuracy})
      places.setBounds(circle.getBounds())
    })
  }
}


export function goBackMap() {

  /*
  Keep information on map loading.
  */

  var backMapInfo = new Object
  backMapInfo.host = window.location.host
  backMapInfo.href = window.location.href

  // Get barba previous, sinon referrer
  if ( barba.history.previous )
    backMapInfo.referrer = barba.history.previous.url
  else
    backMapInfo.referrer = document.referrer

  window.backMapInfo = backMapInfo

  const buttons = document.querySelectorAll('[data-js="backToPreviousPage"]');
  let i;

  for (i = 0; i < buttons.length; i += 1) {
    const button = buttons[i];

    button.addEventListener('click', () => {
      if (window.backMapInfo.host === window.backMapInfo.referrer.split('/')[2])
      {
        if (window.backMapInfo.referrer === backMapInfo.href) {
          window.location.href = '/';
        } else {
          window.location.href = window.backMapInfo.referrer;
        }
      }
      else
      {
        // Different host, so go to home
        window.location.href = '/';
      }
    })
  }
}


export function subMenu() {
  $('.btnSubLvl1 > a, .btnSubLvl1 > span').on('click', function() {
    // Fermeture de tout avant d'en ouvrir un autre
    if (!$(this).parent().hasClass('active')) {
      $('.subLvl1, .subLvl2').animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $('.btnSubLvl1, .btnSubLvl2').removeClass('active')
      $('.subLvl1, .subLvl2').removeClass('open')
      $('.subLvl1').removeClass('container-active')
      $('#overlayLocations .structure').removeClass('one-is-open')
      $('#overlayLocations .subLvl1').removeClass('one-is-open')
    }
    // Gestion ouverture et fermeture
    if (!$(this).parent().find('.subLvl1').hasClass('open')) {
      $(this).parent().find('.subLvl1').animate({ height: 'show' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl1').addClass('open')
      $(this).parent().addClass('active')
      $('#overlayLocations .structure').addClass('one-is-open')
    } else {
      $(this).parent().find('.subLvl1').animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl1').removeClass('open')
      $(this).parent().removeClass('active')
      $('#overlayLocations .structure').removeClass('one-is-open')
      $('#overlayLocations .btnSubLvl1.active .subLvl1').removeClass('one-is-open')
    }
  })

  $('.btnSubLvl2 > a, .btnSubLvl2 > span').on('click', function() {
    // Fermeture de tout avant d'en ouvrir un autre
    if (!$(this).parent().hasClass('active')) {
      $('.subLvl2').animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $('.btnSubLvl2').removeClass('active')
      $('#overlayLocations .btnSubLvl1.active .subLvl1').removeClass('one-is-open')
      $('.subLvl2').removeClass('open')
      $('.subLvl1').removeClass('container-active')
    }
    // Gestion ouverture et fermeture
    if (!$(this).parent().find('.subLvl2').hasClass('open')) {
      $(this).parent().find('.subLvl2').animate({ height: 'show' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl2').addClass('open')
      $('#overlayLocations .btnSubLvl1.active .subLvl1').addClass('one-is-open')
      $(this).parent().addClass('active')
      $(this).parent().parent().addClass('container-active')
    } else {
      $(this).parent().find('.subLvl2').animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeInOutCubic' }})
      $(this).parent().find('.subLvl2').removeClass('open')
      $('#overlayLocations .btnSubLvl1.active .subLvl1').removeClass('one-is-open')
      $(this).parent().removeClass('active')
      $(this).parent().parent().removeClass('container-active')
    }
  })
}


// Fonction initialisant les tiroirs
export function drawers() {
  $('.drawers > li').each(function(){
    if (!$(this).hasClass('open')){
      $(this).find('.drawer').css('display', 'none')
    }
  })

  $('.drawers > li .toggle').on('click', function() {
    if (!$(this).parent().hasClass('open')) {
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
      $(this).parent().addClass('open')
      hashScrolling(this)
    } else {
      $(this).parent().removeClass('open')
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    }
  })
}

function hashScrolling(destination) {
  $('html, body').animate({
    scrollTop: ( destination != '' ? $(destination).offset().top - 125 : 0 )
  }, 600, 'easeInOutExpo')
}


// Fonction gérant l'overlay des lieux sur la carte
export function overlayLocations() {
  return new Overlay({
    name: 'Locations',
    structure: {
      closingClass: 'closingLocations',
      buttons: {
        toggle: '.btnLocations',
        close: '.closeOverlay, .subLvl2 span',
      }
    },
    event: {
      name: 'click',
      speed: 300
    },
    options: {
      noScroll: false,
      global: true
    }
  })
}


// Fonction gérant l'overlay d'un lieu sur la carte
export function overlayLocation() {
  new Overlay({
    name: 'Location',
    create: {
      background: true,
    },
    structure: {
      closingClass: 'closingLocation',
      buttons: {
        toggle: '.hack',
        //toggle: '.subLvl2 span, .btnLocation',
        close: '.closeDetailOverlay', //, .btnLocations',
      }
    },
    event: {
      name: 'click',
      speed: 300
    },
    options: {
      noScroll: true,
      global:true
    }
  })
}
