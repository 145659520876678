// Documentation: https://swiperjs.com/swiper-api
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';
import { tooltipPictureCredits } from './functions';

export default function homeBannerSlider() {
  const sliderName = 'home-banner';
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '[data-swiper="c-home-banner-pagination-bullets"]',
      type: 'bullets',
      clickable: true,
    },
    runCallbacksOnInit: true,
    on: {
      init(sw) {
        const currentSlideIndex = sw.activeIndex;
        const currentSlide = sw.slides[currentSlideIndex];
        const title = currentSlide.querySelector('.c-home-banner__title');
        title.setAttribute('data-separate-sentence-into-words', '');
      },
    },
  });

  quotesSlider.on('slideChange', function() {
    tooltipPictureCredits()
  })
}

export function swiperAlert() {
  const onClose = new CustomEvent('onCloseAlerts');

  if (document.querySelector('.js-alerts')) {
    document.documentElement.style.setProperty('--alerts-height', '40px');
  }

  const closeAlerts = () => {
    dispatchEvent(onClose);
    document.documentElement.style.setProperty('--alerts-height', '0px');
  };

  OBSERVER.add({
    name: 'alerts',
    events: 'click',
    function: closeAlerts,
    targets: '[data-swiper="swiper-alerts-close"]',
  });
  OBSERVER.on('alerts');

  const sliderName = 'alerts';
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '[data-swiper="swiper-alerts-pagination"]',
      type: 'fraction',
    },
    navigation: {
      prevEl: '[data-swiper="swiper-alerts-prev"]',
      nextEl: '[data-swiper="swiper-alerts-next"]',
    },
    runCallbacksOnInit: true,
    on: {
      init(swm) {
        const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
        fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
      },
      slideChange(swm) {
        const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
        fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
      },
    },
  });
}
