import $ from 'jquery';
import { resetDropzone } from './form';
// eslint-disable-next-line import/no-relative-packages
import AddressGoogle from '../../../../../plugins/blanko/forms/assets/js/functions/addressGoogle';
import { OBSERVER } from '../plugins';

// Fonction contenant tous les messages
function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
    };
  }

  return messages;
}

// Fonction exécutant la validation de chaque formulaire
export function formValidation(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      const type = element[0].getAttribute('type');
      if (type === 'radio' || type === 'checkbox') {
        error.appendTo(element.parent().parent().parent().find('.js-form-error'));
      } else {
        error.appendTo(element.parent().parent().find('.js-form-error'));
      }

      // Ajouter la class d'erreur au champs
      const errorField = element.closest('.c-form__grid-item').children();
      const className = errorField.attr('class').split(' ')[0];
      errorField.addClass(`${className}--error`);
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select').on('change', function selectChange() {
    $(this).valid();
  });
  $(submit).on('click', () => {
    $(formElement).submit();
  });
}

// Reset de validation
export function formSuccess(form, data) {
  const formElement = form;

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading');
    $('.stripe-loading-indicator').addClass('loaded');
    $(form)[0].reset();
    resetDropzone(form);
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha.reset();
    }

    // <div class="alert alert-success">x</div>

    let message = 'Le formulaire est bien envoyé !';
    const confirmationContainer = form.querySelector('.js-confirmation-message');

    if (confirmationContainer.innerHTML !== '') {
      message = confirmationContainer.innerHTML;
    } else if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
      message = 'The form has been sent !';
    }

    // Créer l'élément
    const confirmationNode = document.createElement('div');
    confirmationNode.className = 'alert alert-success';
    confirmationNode.textContent = message;

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode);

    // Cacher le formulaire
    formElement.style.display = 'none';

    // $.oc.flashMsg({text: message ,class: 'success'})
  }
}

// La validation des courriels
function validationEmail() {
  // eslint-disable-next-line func-names
  $.validator.addMethod('courriel', function (value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du formulaire d'exemple
export function formExample() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    radioExampleInline: { required: true },
    checkbox: { required: true },
  };
  const messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    radioExampleInline: { required: m.required },
    checkbox: { required: m.required },
  };

  formValidation('#form-example', '#form-example-submit', rules, messages);
}

function requiredAddress() {
  if ($('.tail-select .select-label .label-inner')[0].innerText == 'Finances et trésorerie') {
    $('.js-address-label')[0].innerText = 'Adresse*';
  } else {
    $('.js-address-label')[0].innerText = 'Adresse';
  }
}

// La validation du formulaire de contact
export function formContact() {
  // eslint-disable-next-line no-new
  new AddressGoogle('#form-contact');
  validationEmail();

  const m = getMessages();

  const rules = {
    service: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    message: { required: true },
    address: { required: function (){ return $('.tail-select .select-label .label-inner')[0].innerText == 'Finances et trésorerie' }},
  };
  const messages = {
    service: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    address: { required: m.required },
  };

  formValidation('#form-contact', '#form-contact-submit', rules, messages);

  OBSERVER.add({
    name: 'required_address',
    events: 'change',
    targets: '#form-contact-select',
    function: requiredAddress,
  });

  OBSERVER.on('required_address');
}

export function formNewsletter() {
  validationEmail();

  const m = getMessages();

  const rules = {
    email: { required: true, courriel: true },
    emailConfirmation: { required: true, courriel: true },
    'groups[]': { required: true },
  };
  const messages = {
    email: { required: m.required, courriel: m.email, email: m.email },
    emailConfirmation: { required: m.required, courriel: m.email, email: m.email },
    'groups[]': { required: m.required },
  };

  formValidation('#form-newsletter', '#form-newsletter-submit', rules, messages);
}

// La validation du formulaire de contact
export function formJobs() {
  validationEmail();

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { required: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { required: m.required },
  };

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages);
}

// La validation du formulaire dans la page de formulaire
export function formPage() {
  validationEmail();

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    civicNumber: { required: true },
    street: { required: true },
    city: { required: true },
    zip: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { required: true },
    firstname2: { required: true },
    lastname2: { required: true },
    completeAddress: { required: true },
    email2: { required: true, courriel: true },
    amount: { required: true },
    branches: { required: true },
    firstname3: { required: true },
    lastname3: { required: true },
    civicNumber2: { required: true },
    street2: { required: true },
    city2: { required: true },
    zip2: { required: true },
    phone2: { required: true, minlength: 12 },
    email3: { required: true, courriel: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    civicNumber: { required: m.required },
    street: { required: m.required },
    city: { required: m.required },
    zip: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { required: m.required },
    firstname2: { required: m.required },
    lastname2: { required: m.required },
    completeAddress: { required: m.required },
    email2: { required: m.required, courriel: m.email, email: m.email },
    amount: { required: m.required },
    branches: { required: m.required },
    firstname3: { required: m.required },
    lastname3: { required: m.required },
    civicNumber2: { required: m.required },
    street2: { required: m.required },
    city2: { required: m.required },
    zip2: { required: m.required },
    phone2: { required: m.required, minlength: m.phone },
    email3: { required: m.required, courriel: m.email, email: m.email },
  };

  formValidation('#form-page', '#form-page-submit', rules, messages);
}
