import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    // alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    setTimeout(() => {
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
      },
    },
    animations: {
      selector: '.c-overlay-dropdown__sections',
      styles: [{
        property: 'height',
        value: 'dataset',
        easing: 'easeInOutQuart',
      }],
    },
    options: {
      speed: 1000,
      closeOnResize: true,
    },
    events: {
      close: true,
      trigger: true,
    },
  }).init();

  const html = document.querySelector('html');
  const sectionsContainer = document.querySelector('.c-overlay-dropdown__sections');

  const adaptWidth = () => {
    const activeDataSection = html.getAttribute('data-section');
    const activeSection = html.querySelector(`.c-overlay-dropdown [data-section=${activeDataSection}]`);
    const activeSectionWidth = activeSection.offsetWidth;

    sectionsContainer.style.width = `${activeSectionWidth}px`;
  };

  OBSERVER.add({
    name: 'overlayDropdown',
    events: 'onTriggerOverlayDropdown',
    function: adaptWidth,
  });
  OBSERVER.on('overlayDropdown');
}

export function overlaySearch() {
  const search = new Overlay({
    name: 'search',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-search"]',
        close: '[data-js="close-overlay-search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  });

  search.init();

  const html = document.querySelector('html');
  const inputSearch = document.querySelector('[data-js="overlay-search-input"]');
  const inputValue = document.querySelector('.c-overlay-search [data-inputvalue]');

  const onOpenComplete = () => {
    inputSearch.focus();
  };

  const onCloseComplete = () => {
    inputSearch.value = '';
    inputValue.setAttribute('data-inputvalue', '');
  };

  let ajaxRequest = false;
    const changeSection = (e) => {
      const inputSearch = e;
      const html = document.querySelector('html');

      if (ajaxRequest) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort();
        if (html.dataset.section !== 'suggestions') {
          search.trigger('suggestions');
        }
      }

      if (inputSearch.target.value.length >= 3) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: e.currentTarget.value },
          update: { 'rechercheAutocomplete::autocomplete': '#overlay-search #autocomplete' },
          complete(data) {
            // Results loaded.
            ajaxRequest = false;

            // On affiche l'overlay des suggestions juste si ya des résultats
            if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
              if (html.dataset.section !== 'fast-results') {
                search.trigger('fast-results');
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (html.dataset.section !== 'suggestions') {
                search.trigger('suggestions');
              }
            }
          },
        });
      } else if (inputSearch.target.value.length < 3 && html.dataset.section !== 'suggestions') {
        search.trigger('suggestions');
      }
    };

  const onClearInput = () => {
    if (html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  };

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onOpenCompleteOverlaySearch',
    function: onOpenComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    targets: '[data-js="overlay-search-input"]',
    function: changeSection,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    targets: '.js-overlay-search-clear-input',
    function: onClearInput,
  });
  OBSERVER.on('overlaySearch');
}

export function overlayMenu() {
  const menu = new Overlay({
    name: 'menu',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-menu"]',
        close: '[data-js="close-overlay-menu"]',
        switch: '[data-js="trigger-overlay-search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  });

  menu.init();
}

export function overlayCalendar() {
  const calendar = new Overlay({
    name: 'calendar',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  calendar.init();
}

export function overlayDetail() {
  window.overlayRepertoire = new Overlay({
    name: 'detail',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        // open: '[data-js="open-overlay-detail"]',
        close: '[data-js="close-overlay-detail"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  window.overlayRepertoire.init();
}

// Fonction gérant l'overlay du popup
export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '[data-js="close-overlay-popup"]',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    ovPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay
    const addHeightAttributeToContainer = (nameEventOverlay, targetContainerOverlay) => {
      const containerOverlay = document.querySelector(targetContainerOverlay);

      function onOpen() {
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      function onCloseComplete() {
        containerOverlay.style.height = null;
      }

      function onResize() {
        containerOverlay.style.height = null;
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onOpenOverlay${nameEventOverlay}`,
        function: onOpen,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onCloseCompleteOverlay${nameEventOverlay}`,
        function: onCloseComplete,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: 'resize',
        function: onResize,
      });
      OBSERVER.on(`overlay${nameEventOverlay}`);
    };

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer('Popup', '[data-overlay-container="popup"]');
  }
}
