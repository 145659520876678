import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import anime from 'animejs/lib/anime.es';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import { formatBytes, isMobile } from './helper';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel() {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea';
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    events: 'input',
    targets: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight() {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    events: 'input',
    targets: 'textarea',
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = (e) => {
    const input = e.currentTarget.parentNode.querySelector('.js-input-to-clear');
    input.value = '';
    input.focus();
    input.parentElement.parentElement.dataset.inputvalue = '';
    input.classList.remove('valid');
  };

  OBSERVER.add({
    name: 'clearInput',
    events: 'click',
    targets: '.js-clear-input',
    function: onClick,
  });

  OBSERVER.on('clearInput');
};

// Retourne la dropZone en fonction de son nom
export function getDropzone(name) {
  let selectedDropzone = false;
  // eslint-disable-next-line no-undef
  Dropzone.instances.forEach((dropzone) => {
    if (dropzone.element.id === name) selectedDropzone = dropzone;
  });
  return selectedDropzone;
}

// Reset les dropzones du formulaire
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  const dropZoneList = $('.fileField', $(formObj));

  // reset des dropzones du form
  dropZoneList.each((index, element) => {
    // pour chaque drop zone trouvé dans le form on cherche son instance de Dropzone
    const dropZoneInstance = getDropzone(element.id);
    dropZoneInstance.removeAllFiles();
  });
}

// Permet de changer le label des input files
export function fileUpload() {
  const clear = (name, element, currentLabelText) => {
    const paramEl = element;
    paramEl.value = '';
    paramEl.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText;
    paramEl.parentNode.dataset.file = '';
    OBSERVER.off(`clear${name}`);
  };

  const changeLabel = (e) => {
    const self = e;
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text');
    const currentLabelText = label.innerText;
    let i;
    let newLabel = '';
    const fileLength = e.currentTarget.files.length;

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i += 1) {
          const file = e.currentTarget.files[i];
          const name = e.currentTarget.getAttribute('name');
          newLabel += `${i + 1}. `;

          if ('name' in file) newLabel += `fichier: ${file.name}, `;
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`;

          const onClear = () => clear(name, self.target, currentLabelText);

          OBSERVER.add({
            name: `clear${name}`,
            events: 'click',
            targets: e.currentTarget.previousElementSibling,
            function: onClear,
          });

          OBSERVER.on(`clear${name}`);
        }
        e.currentTarget.parentNode.dataset.file = newLabel;
        label.innerText = newLabel;
      }
    }
  };

  OBSERVER.add({
    name: 'fileUpload',
    events: 'change',
    targets: 'input[type=file].js-file-field-input',
    function: changeLabel,
  });

  OBSERVER.on('fileUpload');
}

// Permet d'ajouter plusieurs fichiers (sans Renatio)
export function multipleFilesUpload() {
  const makeid = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const deleteItem = (e) => {
    anime.timeline({
      targets: e.currentTarget.parentNode.parentNode.parentNode,
      duration: 300,
      easing: 'easeOutCubic',
    }).add({
      height: '0px',
      opacity: 0,
    }).finished.then(() => {
      e.target.parentNode.parentNode.parentNode.parentNode.remove();
    });
  };

  const addItem = (e) => {
    const originalID = e.currentTarget.id;
    const originalName = e.currentTarget.name;
    const inputContainer = e.currentTarget.parentNode;
    const containerList = e.currentTarget.parentNode.parentNode.parentNode.querySelector('.js-multiple-files-field-files-list');
    const template = e.currentTarget.parentNode.parentNode.querySelector('.js-multiple-files-field-file-block-template').content.cloneNode(true);

    if ($(e.currentTarget).get(0).files.length > 0) {
      const id = makeid();
      const file = $(e.currentTarget).get(0).files[$(e.currentTarget).get(0).files.length - 1];

      let previewSrc = `/themes/${window.config.theme_path}/assets/medias/images/default/multiple-files-field-preview-default.png`;
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        previewSrc = URL.createObjectURL(file);
      }

      template.querySelector('.js-multiple-files-field-file-image').src = previewSrc;
      template.querySelector('.js-multiple-files-field-file-title').textContent = `${file.name}`;
      template.querySelector('.js-multiple-files-field-file-size').textContent = `${formatBytes(file.size)}`;
      template.querySelector('.js-multiple-files-field-file-instance').setAttribute('id', `container-${id}`);
      containerList.appendChild(template);
      e.currentTarget.setAttribute('id', id);
      document.querySelector(`#container-${id}`).appendChild(e.currentTarget);
      const height = `${document.querySelector(`#${id}`).parentNode.querySelector('.js-multiple-files-field-file-height').clientHeight}px`;

      anime.timeline({
        targets: document.querySelector(`#${id}`).parentNode,
        duration: 300,
        easing: 'easeOutCubic',
      }).add({
        height,
        opacity: 1,
      }).add({
        height: '100%',
      });
    }

    const newInput = document.createElement('input');
    newInput.setAttribute('class', 'multiple-files-field__button-input || js-multiple-files-field-button-input');
    newInput.setAttribute('type', 'file');
    newInput.setAttribute('name', originalName);
    newInput.setAttribute('id', originalID);
    newInput.setAttribute('accept', '.pdf, .jpg, .png');

    inputContainer.appendChild(newInput);

    OBSERVER.off('multipleFilesUpload');
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'change',
      targets: 'input[type=file].js-multiple-files-field-button-input',
      function: addItem,
    });
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'click',
      targets: '.js-multiple-files-field-file-delete',
      function: deleteItem,
    });
    OBSERVER.on('multipleFilesUpload');
  };

  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'change',
    targets: 'input[type=file].js-multiple-files-field-button-input',
    function: addItem,
  });
  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'click',
    targets: '.js-multiple-files-field-file-delete',
    function: deleteItem,
  });
  OBSERVER.on('multipleFilesUpload');
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect() {
  const themePath = window.config.theme_path;

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select('select', { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  let i;
  let j;
  const selectBoxes = document.querySelectorAll('.tail-select');
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (i = 0; i < selectBoxesLength; i += 1) {
    const tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option');
    const nativeSelect = selectBoxes[i].previousElementSibling;
    const nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)');

    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-pointer`,
    );
    svgElem.appendChild(useElem);
    // selectBoxes[i].querySelector('.select-label').appendChild(svgElem)
    selectBoxes[i].appendChild(svgElem);

    for (j = 0; j < nativeSelectItems.length; j += 1) {
      // Looper dans chaques item du Tail Select
      const imgPath = nativeSelectItems[j].dataset.image;
      if (typeof imgPath !== 'undefined' && imgPath !== '') {
        const newImage = document.createElement('img');
        newImage.src = imgPath;
        tailSelectItems[j].classList.add('has-image');
        tailSelectItems[j].appendChild(newImage);
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat
  // sélectionné (pas utilisé pour le moment)
  /*
  OBSERVER.add({
    name: 'tailSelectChange',
    events: 'change',
    targets: '.field--select select',
    function: tailSelectChange
  })
  OBSERVER.on('tailSelectChange')

  function tailSelectChange(e){
    const parentEl = e.target.parentElement;
    var tailActive = parentEl.querySelector('.label-inner')

    if(tailActive){
      const index = e.target.selectedIndex-1
      var selectedTailItem = parentEl.querySelectorAll('.tail-select .dropdown-option')[index]
      if(selectedTailItem.classList.contains('has-image')){
        var newImage = document.createElement('img')
        newImage.src = selectedTailItem.querySelector('img').src
        tailActive.appendChild(newImage)
      }
    }
  }
  */
}

// Initialisation du champ de timepicker
export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Initialisation du champ de calendrier
export function calendarInput() {
  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {
    const datepickersWrapper = document.querySelectorAll('.js-datepickerWrapper');
    const datepickers = [];

    Datepicker.locales.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      today: "Aujourd'hui",
      monthsTitle: 'Mois',
      clear: 'Effacer',
      weekStart: 0,
      format: 'dd/mm/yyyy',
    };

    for (let i = 0; i < datepickersWrapper.length; i += 1) {
      datepickers.push(
        new Datepicker(datepickersWrapper[i], {
          language: 'fr',
          prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
          nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
          format: 'dd MM yyyy',
          todayHighlight: true,
        }),
      );
    }

    const onFocus = (e) => {
      const datepicker = e.target.parentElement.parentElement.querySelector('.js-datepickerWrapper');
      datepicker.classList.add('show');
    };

    const onChangeDate = (e) => {
      for (let i = 0; i < datepickersWrapper.length; i += 1) {
        if (e.currentTarget === datepickers[i].element) {
          e.currentTarget.parentElement.querySelector('input').value = datepickers[i].getDate('dd MM yyyy');
          e.currentTarget.classList.remove('show');
        }
      }
    };

    const closeCalendar = () => {
      for (let i = 0; i < datepickersWrapper.length; i += 1) {
        datepickersWrapper[i].classList.remove('show');
      }
    };

    const preventClose = (e) => {
      e.stopPropagation();
    };

    OBSERVER.add({
      name: 'input-calendar',
      events: 'focus click',
      targets: '.js-input-calendar input',
      function: onFocus,
    });
    OBSERVER.add({
      name: 'input-calendar',
      events: 'changeDate',
      targets: '.js-datepickerWrapper',
      function: onChangeDate,
    });
    OBSERVER.add({
      name: 'input-calendar',
      events: 'click',
      function: closeCalendar,
    });
    OBSERVER.add({
      name: 'input-calendar',
      events: 'click',
      targets: '.js-input-calendar',
      function: preventClose,
    });
    OBSERVER.on('input-calendar');

    // Si on est en mobile, utiliser les calendriers en HTML5
  } else {
    document.querySelectorAll('.js-input-calendar input').forEach(
      (element) => element.setAttribute('type', 'date'),
    );
  }
}

// Le package à appliquer pour les formulaires
export function formsPackage() {
  inputsAndTextareaLabel();
  fileUpload();
  multipleFilesUpload();
  tailSelect();
  textareaHeight();
  calendarInput();
  timepicker();
}
