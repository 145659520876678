import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { SCROLLFIRE, OBSERVER } from './plugins';
import fadeTransition from './transitions/boring-transition';
import {
  calendar, backendKeyBind, ogContent, getImageHeight, changeSelect, zoomFontSize, tooltipPictureCredits, reloadHTMLScript, adresseSearchResultsLoaded, formSearchByAddressAutocomplete
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, tailSelect, clearInput, inputsAndTextareaLabel,
} from './functions/form';
import {
  overlayShare, overlayDropdown, overlayMenu, overlaySearch, overlayCalendar, overlayDetail, overlayPopup,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formContact, formExample, formJobs, formNewsletter, formPage, formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { fontSizeBasedOnCaracters, hundredVH, removeHoverOnMobile } from './functions/helper';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import homeBannerSlider, { swiperAlert } from './functions/sliders';
import dynamicClearInput from './functions/dynamicClearInput';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, subMenu, drawers, goBackMap,
} from './functions/map/map';
import { initRepertoire } from './functions/repertoire';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.adresseSearchResultsLoaded = adresseSearchResultsLoaded;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    reloadHTMLScript();
    backendKeyBind();
    ShareButtons.update();
    removeHoverOnMobile();
    hundredVH();
    fontSizeBasedOnCaracters();
    getImageHeight();
    inputsAndTextareaLabel();
    clearInput();
    clickToScrollToBlock({ selector: '[data-js="scroll-to-top"]' });
    overlayShare();
    overlayDropdown();
    overlaySearch();
    overlayMenu();
    overlayPopup();
    swiperAlert();
    zoomFontSize();
    tooltipPictureCredits();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          masksPackage();
          dynamicPackage();
          formExample();
          calendar();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
          initFormBuilder();

          if (document.querySelector('#form-example')) {
            formsPackage();
          }
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          homeBannerSlider();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#news-sort');
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#events-sort');
          calendar();
          overlayCalendar();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventDetailView',
        beforeEnter() {
          tailSelect();
          dynamicPackage();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'addressSearchView',
        beforeEnter() {
          dynamicClearInput();
          rubrics();
          dynamicPackage();
          formSearchByAddressAutocomplete();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionsView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'businessDirectoryView',
        beforeEnter() {
          dynamicPackage();
          overlayDetail();
          initRepertoire();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          rubrics();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          formsPackage();
          masksPackage();
          changeSelect('#form-contact-select');
          formContact();
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'facilitiesStateView',
        beforeEnter() {
          rubrics();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'promoView',
        beforeEnter() {
          dynamicPackage();
          rubrics();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formNewsletter();
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'fastLinksView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formJobs();
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobDetailView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formJobs();
          dynamicPackage();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'formView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formPage();
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          subMenu();
          drawers();
          googleMap();
          goBackMap();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
