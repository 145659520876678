import $ from 'jquery'
import Accordions from '../classes/accordions';

window.locations = []
window.loadSidePanel = loadSidePanel

export function initRepertoire() {

  window.repertoireEvent = new CustomEvent('repertoireEvent');
  window.repertoireTest = true;

  const accordions = new Accordions();
  accordions.offset = 0;
  accordions.init();
  
  $('.js-accordion').each(function(i, element) {
    if ($(element).hasClass('js-accordion-opened')) {
      callLoadCateg($(element))
      accordions.open(element)
    }
  })

  $('.js-accordion .js-accordion-toggle').click( function() {
    
    var cat = $(this).parent()
    if (cat.hasClass('js-accordion-opened') )
    {
      //loading new locations.
      callLoadCateg(cat)
      //met le slug de la categ dans l'URL
      history.pushState({}, '', '/repertoire-des-entreprises/' + cat.data('slug'))
    }
    else {
      history.pushState({}, '', '/repertoire-des-entreprises')
    }
  })
  
  $('.js-accordions a').click(function() {
    var locationsIdx = parseInt($(this).data('locationsidx'))
    loadSidePanel(window.locations[locationsIdx])
    document.title = window.locations[locationsIdx].title_fr + ' | Répertoire des entreprises | Ville de l’Ancienne-Lorette' 
    history.pushState({}, '', window.locations[locationsIdx].url)
    return false
  })
  
  $('[data-js=close-overlay-detail]').click( function() {
    document.title = 'Répertoire des entreprises | Ville de l’Ancienne-Lorette'
    history.pushState({}, '', window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')))
    return false
  })
}

function callLoadCateg(cat) {
  
  $.request('repertoiresIndex::onChangeCategorie', {
    loading: $.oc.stripeLoadIndicator,
    data: { 
      'categoryId' : cat.data('id'),
    },
    success: function(data) {
      loadLocationsArray(data.items)
    }
  })
}

function loadLocationsArray(locs) {  
  window.locations = locs
  if (window.repertoireTest) {
    dispatchEvent(window.repertoireEvent);
    window.repertoireTest = false
  }
}

function loadSidePanel(json) {
  $('.js-panelFeatures').hide()
  
  $('#js-panelTitre').html(json.title_fr)

  if(json.facebook)
  {
    $('#js-panelFacebook').attr('href',json.facebook).parent().show()
  }

  if(json.instagram)
  {
    $('#js-panelInstagram').attr('href',json.instagram).parent().show()
  }

  if(json.linkedin)
  {
    $('#js-panelLinkedIn').attr('href',json.linkedin).parent().show()
  }

  if(json.youtube)
  {
    $('#js-panelYoutube').attr('href',json.youtube).parent().show()
  }

  if(json.resizedImage)
  {
    $('#js-panelImage').attr('src', json.resizedImage)
    $('#js-panelImageFeature').show()
  }

  if(json.description_fr)
  {
    $('#js-panelDescription').html(json.description_fr)
    $('#js-panelDescription').show()
  }

  if(json.schedule_fr)
  {
    $('#js-panelHoraire div').html(json.schedule_fr)
    $('#js-panelHoraire').show()
  }

  if(json.address)
  {
    $('#js-panelAdresse').html(json.address)
    $('#js-panelAdresseFeature').show()
  }

  if(json.googlemap)
  {
    $('#js-panelGoogleMap').attr('href', json.googlemap).show()
  }

  if(json.email)
  {
    $('#js-panelCourriel').attr('href', 'mailto:' + json.email).show()
  }

  if(json.phone)
  {
    $('#js-panelTelephone span').html(json.phone)
    $('#js-panelTelephone').attr('href', 'tel:' + json.phone).show()
  }

  if(json.post)
  {
    $('#js-panelTelephonePost').html(', poste ' + json.post).show()
  }

  if(json.website)
  {
    $('#js-panelSiteWeb').attr('href', json.website).show()
  }

  window.overlayRepertoire.open()
}